import api from './api';


class ItemService {
    async getItems() {
        var items_list = []

        const response = await api.get("/item");

        items_list = response.data
      
        return items_list;
    }
    async getItem(id) {
        var item = {}

        const response = await api.get("/item/"+id);
      
        item = response.data     

        return item;
    }    
    async saveItem(id, desc, title, lense, choices, pillar, effort) {

        var request = { 
            "item_desc": desc,
            "item_title": title,
            "item_lense":lense,
            "item_hri": choices,
            "item_pillar": pillar,
            "item_effort": effort
        }

        const response = await api.put("/item/"+id, request);

        return response.data;
    }    
    async createItem(desc, title, lense, choices, pillar, effort) {

        var request = { 
            "item_desc": desc,
            "item_title": title,
            "item_lense":lense,
            "item_hri": choices,
            "item_pillar": pillar,
            "item_effort": effort
        }

        const response = await api.post("/item", request);

        return response.data;
    }   

    async deleteItem(id) {
        const response = await api.delete("/item/"+id);

        return response.data;        
    }
  }
  
  export default new ItemService();