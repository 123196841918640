import api from './api';


class LenseService {
    async getLenses() {
        var lense_dict = {}
        const response = await api.get("/lense");
  
        lense_dict = response.data;        
        
        return lense_dict;
    } 
    async getPillars() {
        var pillars_dict = {}
        const response = await api.get("/lense/pillars");
  
        pillars_dict = response.data;        
        
        return pillars_dict;
    } 


  }
  
  export default new LenseService();